import React, { FC } from 'react';

import styled from 'styled-components';

import { Paging } from '../../types';
import Pagination from '../Toolkit/Pagination/Pagination';

type SearchPagePaginationProps = {
  paging: Paging;
  onUpdate: (query: any) => void;
  disabled?: boolean;
};

const PaginationWrapper = styled.div`
  text-align: center;
  padding: 28px 0;
  @media only screen and (min-width: 704px) {
    padding: 56px 0;
  }
`;

const PaginationResults = styled.p`
  font-size: 14px;
  padding-top: 24px;
  color: #333333;
`;

const showPagination = (paging: Paging) => {
  const { currentPage, pageSize, totalResults, nextFrom } = paging;
  return !(
    totalResults === 0 ||
    (currentPage === 1 && totalResults <= pageSize && nextFrom === 0)
  );
};

const SearchPagination: FC<SearchPagePaginationProps> = (
  props: SearchPagePaginationProps,
) => {
  const { paging, onUpdate, disabled } = props;
  const formattedDisplayingFrom = paging.displayingFrom.toLocaleString();
  const formattedDisplayingTo = paging.displayingTo.toLocaleString();
  const formattedTotalResults = paging.totalResults.toLocaleString();

  return (
    <PaginationWrapper>
      {showPagination(paging) && (
        <Pagination
          {...paging}
          searchQueryGroup="paging"
          onUpdate={onUpdate}
          disabled={disabled}
        />
      )}
      <PaginationResults data-testid="pagination-results">
        Showing {formattedDisplayingFrom} - {formattedDisplayingTo} of{' '}
        {formattedTotalResults}
      </PaginationResults>
    </PaginationWrapper>
  );
};

export default SearchPagination;
