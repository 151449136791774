import React from 'react';

import { Pagination } from '@dsch/pagination';
import styled from 'styled-components';

import {
  NewButton,
  NewButtonTypes,
  NewButtonSizes,
} from '../../../components/Toolkit/NewButton/NewButton';

const StyledPagination = styled(Pagination)`
  font-size: 14px;

  & ::selection {
    color: inherit;
    background: none;
  }

  @media only screen and (max-width: 600px) {
    .hide {
      display: none;
    }
  }
`;

type DaftPaginationProps = {
  currentPage: number;
  totalPages: number;
  onUpdate: (query: any) => void;
  pageSize: number;
  searchQueryGroup: string;
  startTime?: number;
  disabled?: boolean;
};

const StyledBreak = styled.span`
  display: none;
  @media only screen and (min-width: 600px) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 42px;
    border: 1px solid #bbbbbb;
    background: #ffffff;
    border-radius: 4px;
    cursor: default;
    margin: 0 24px;
  }
`;

const DaftPagination = (props: DaftPaginationProps) => {
  const { disabled } = props;
  return (
    <StyledPagination
      renderBreakView={(props) => <StyledBreak {...props}>...</StyledBreak>}
      renderPageButton={(props) => {
        const { highlight, onClick, text } = props;
        return (
          <NewButton
            classNames={!highlight ? 'hide' : ''}
            buttonText={`${text}`}
            buttonType={
              highlight ? NewButtonTypes.PRIMARY : NewButtonTypes.TERTIARY
            }
            buttonSize={NewButtonSizes.X_SMALL}
            disabled={disabled}
            {...props}
            onClick={onClick as any}
          />
        );
      }}
      renderPrevButton={(props) => (
        <NewButton
          buttonText={'< Prev'}
          buttonType={NewButtonTypes.TERTIARY}
          buttonSize={NewButtonSizes.X_SMALL}
          data-testid="go-to-prev-page"
          {...props}
          disabled={disabled}
        />
      )}
      renderNextButton={(props) => (
        <NewButton
          buttonText={'Next >'}
          buttonType={NewButtonTypes.TERTIARY}
          buttonSize={NewButtonSizes.X_SMALL}
          data-testid="go-to-next-page"
          {...props}
          disabled={disabled}
        />
      )}
      {...props}
    />
  );
};

export default DaftPagination;
